import {
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injectable,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {PositioningService} from './services/positioning.service';
import {ensureWorkingFontFamily} from "./styleExtractor";

@Injectable({
  providedIn: 'root'
})
export class ModalTrackerService {
  public isModalOpen = false;
}

export interface JQuery {
  // noinspection JSUnusedLocalSymbols
  trumbowyg(command: 'html', content?: string): string;

  // noinspection JSUnusedLocalSymbols
  trumbowyg(command: 'openModal', options: JQueryTrumbowyg.ModalOptions): JQuery;

  // noinspection JSUnusedLocalSymbols
  trumbowyg(command: 'openModalInsert', options: JQueryTrumbowyg.ModalInsertOptions): JQuery;

  // noinspection JSUnusedLocalSymbols
  trumbowyg(command: 'closeModal' | 'saveRange' | 'restoreRange' | 'empty' | 'enable' | 'disable' | 'toogle' | 'destroy'): void;

  // noinspection JSUnusedLocalSymbols
  trumbowyg(command: 'getRange'): Range;

  // noinspection JSUnusedLocalSymbols
  trumbowyg(command: 'getRangeText'): string;

  // noinspection JSUnusedLocalSymbols
  trumbowyg(options?: JQueryTrumbowyg.Options): JQuery;
}

export declare namespace JQueryTrumbowyg {
  export interface Trumbowyg extends StaticOptions {
    // noinspection JSUnusedLocalSymbols
    openModal(title: string, content: string): JQuery;

    // noinspection JSUnusedLocalSymbols
    openModalInsert(title: string, fields: { [fieldName: string]: ModalFields },
      callback: (values: string[]) => boolean): JQuery;

    closeModal(): void;

    saveRange(): void;

    restoreRange(): void;

    getRangeText(): string;

    getRange(): Range;

    // noinspection JSUnusedLocalSymbols
    html(html?: string): string;

    empty(): void;

    // noinspection JSUnusedLocalSymbols
    setDisabled(disabled: boolean): void;

    toggle();
  }

  interface StaticOptions {
    langs: any;
    plugins: any;
    svgPath: string | boolean;
    hideButtonTexts: boolean;
  }

  interface ModalOptions {
    title: string;
    content: string;
  }

  interface ModalInsertOptions {
    title: string;
    fields: {
      [fieldName: string]: ModalFields;
    }
    callback: (values: string[]) => boolean;
  }

  interface ModalFields {
    label?: string;
    name?: string;
    value?: string;
  }

  interface Options {
    lang?: string;

    fixedBtnPane?: boolean;
    fixedFullWidth?: boolean;
    autogrow?: boolean;
    autogrowOnEnter?: boolean;
    imageWidthModalEdit?: boolean;

    prefix?: string;

    urlProtocol?: boolean;
    minimalLinks?: boolean;

    semantic?: boolean;
    resetCss?: boolean;
    removeformatPasted?: boolean;
    tagsToRemove?: string[];
    btns?: string[][];
    btnsDef?: {
      [btnName: string]: ButtonDefinition;
    };

    inlineElementsSelector?: string;

    pasteHandler?: ((event: any) => void)[];
    imgDblClickHandler?: (event: any) => void;

    plugins?: {
      [pluginName: string]: PluginDefinition;
    };
  }

  interface PluginDefinition {
    init?: (trumbowyg: Trumbowyg) => void;
    tagHandler?: (element: any, trumbowyg: Trumbowyg) => any[];
    destroy?: () => void;
    [key: string]: any;
  }

  interface ButtonDefinition {
    dropdown?: string[];
    fn?: string | (() => any);
    tag?: string;
    title?: string;
    text?: string;
    isSupported?: () => boolean;
    key?: string;
    param?: string;
    forceCSS?: boolean,
    class?: string,
    ico?: string;
    hasIcon?: boolean;
  }
}

declare let jQuery: any;

export const defaultTrumbowygOptions: JQueryTrumbowyg.Options = {
  urlProtocol: true,
  minimalLinks: true,
  semantic: false,
  btns: [['fontfamily', 'fontsize'], ['bold', 'italic', 'underline', 'strikethrough'], ['foreColor', 'backColor'],
    ['unorderedList', 'orderedList'], ['horizontalRule', 'upload'], ['removeformat']],
  plugins: {
    colors: {
      colorList: ['ffffff', '000000', 'eeece1', '1f497d', '4f81bd', 'c0504d', '9bbb59', '8064a2', '4bacc6',
        'f79646', 'ffff00', 'f2f2f2', '7f7f7f', 'ddd9c3', 'c6d9f0', 'dbe5f1', 'f2dcdb', 'ebf1dd', 'e5e0ec',
        'dbeef3', 'fdeada', 'fff2ca', 'd8d8d8', '595959', 'c4bd97', '8db3e2', 'b8cce4', 'e5b9b7', 'd7e3bc',
        'ccc1d9', 'b7dde8', 'fbd5b5', 'ffe694', 'bfbfbf', '3f3f3f', '938953', '548dd4', '95b3d7', 'd99694',
        'c3d69b', 'b2a2c7', 'b7dde8', 'fac08f', 'f2c314', 'a5a5a5', '262626', '494429', '17365d', '366092',
        '953734', '76923c', '5f497a', '92cddc', 'e36c09', 'c09100', '7f7f7f', '0c0c0c', '1d1b10', '0f243e',
        '244061', '632423', '4f6128', '3f3151', '31859b', '974806', '7f6000', '009900','ff0000', '00ff00', '0000ff',
        '00ffff', 'ff00ff']
    },
    upload: {
        serverPath: environment.api + '{pmDomain}/images/',
        fileFieldName: 'image',
        xhrFields: {
          withCredentials: true
        }
    }
  }
};

export const defaultButtonsWithLinking = () => {
  const linkButton = ['link'];
  const buttons = [...defaultTrumbowygOptions.btns];
  buttons.splice(-1, 0, ...[linkButton]);
  return buttons;
}

export const internalDocumentTrumbowygOptions: JQueryTrumbowyg.Options = {
  ...defaultTrumbowygOptions,
  btns: [['formatting'], ...defaultButtonsWithLinking(), ['fullscreen']]
}

@Directive({
  selector: '[pmTrumbowyg]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TrumbowygDirective),
  }]
})
export class TrumbowygDirective implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() pmTrumbowyg: JQueryTrumbowyg.Options;
  @Input() offsetElement: any;
  @Input() enabled = true;
  @Input() autoFocus = true;
  @Output() textUpdate = new EventEmitter<string>();

  private onChangeListenerFn: ((value: any) => void)[] = [];
  private onTouchedListenerFn: (() => void)[] = [];

  public setModalOpen(isOpen: boolean) {
    this.modalTrackerService.isModalOpen = isOpen;
    console.log('Modal is now:' + (isOpen ? 'open' : 'closed'));
  }

  constructor(private el: ElementRef, private translate: TranslateService, private checkRight: PositioningService, private modalTrackerService: ModalTrackerService) {
  }

  tbwInit() {
    if (!!this.offsetElement) {
      this.checkRight.doCheck(this.offsetElement.getElementsByClassName('trumbowyg-button-pane')[0]);
    }
    if (this.autoFocus) {
      jQuery('.trumbowyg-editor').focus();
    }
  }

  get html() {
    return jQuery(this.el.nativeElement).html();
  }

  tbwChange() {
    const value = ensureWorkingFontFamily(this.html);
    this.textUpdate.emit(value);
    this.onChangeListenerFn.forEach(fun => fun(value));
  }

  tbwBlurred() {
      this.onTouchedListenerFn.forEach((fun => fun()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const enableChange: SimpleChange = changes['enabled'];
    if (!!enableChange) {
      if (enableChange.currentValue && !enableChange.previousValue) {
        this.enable();
      } else if (!enableChange.currentValue && enableChange.previousValue) {
        this.disable()
      }
    }
  }

  ngOnInit() {
    if (this.enabled) {
      this.enable();
    }
  }

  ngOnDestroy() {
    if ((this?.el?.nativeElement as HTMLElement)?.classList?.contains('trumbowyg-editor')) {
      this.disable()
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChangeListenerFn.push(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedListenerFn.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.ngOnChanges({enabled: new SimpleChange(isDisabled, !isDisabled, false)});
  }

  writeValue(obj: any): void {
    jQuery(this.el.nativeElement).trumbowyg('html', obj);
  }

  private disable() {
    this.tbwChange();
    jQuery(this.el.nativeElement).trumbowyg('destroy');
    jQuery(this.el.nativeElement).off();
  }

  private enable() {
    const language = this.translate.currentLang.split('_')[0];
    window.setTimeout(() => {
      jQuery(this.el.nativeElement).trumbowyg({
        ...this.pmTrumbowyg,
        lang: language
      }).on('tbwinit', () => this.tbwInit())
        .on('tbwchange tbwpaste', () => this.tbwChange())
        .on('tbwblur', () => this.tbwBlurred())
        .on('tbwmodalopen', () => this.setModalOpen(true))
        .on('tbwmodalclose', () => this.setModalOpen(false))
    }, 10);
  }
}
