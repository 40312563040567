import {animate, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {isLink, Link} from '../../shared/model/link';
import {convertToPixelSize, Process, Size} from '../../shared/model/process';
import {RenderedElement} from '../../shared/model/rendered/rendered-element';
import {isRenderedProcess, RenderedProcess} from '../../shared/model/rendered/rendered-process';
import {isTemplateId, TemplateId} from '../../shared/model/template-id';
import {Tree} from '../../shared/model/tree';
import {InteractService} from '../../shared/services/interact.service';
import {NavigationService} from '../../shared/services/navigation.service';
import {VisitService} from '../../shared/services/visit.service';
import {ensureWorkingFontFamily, extractStyle} from '../../shared/styleExtractor';
import {ApplyFormat, SetFormatSource} from '../../state-management/actions/formatting.actions';
import {
  ActivateElement,
  DeactivateElement,
  UpdateTreeElementSize,
  UpdateTreeElementText
} from '../../state-management/actions/tree.actions';
import {
  AppState,
  getFormattingSourceNode,
  getTemplatesShowing,
  getTreeNode,
  getTreeRoot
} from '../../state-management/reducers';
import {ActivationType, NodeActivation} from '../../state-management/reducers/tree.reducer';
import {ProcessComponent} from './process';
import {PromanLinkService} from './shared/proman-link.service';

@Component({
  selector: 'pm-process-tree',
  templateUrl: './process-tree.component.html',
  styleUrls: ['./process-tree.component.css'],
  animations: [ // @formatter:off
    trigger('rowFade', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.2s ease-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('.2s ease-out', style({ opacity: '0' })),
      ])
    ]),
    trigger('processFade', [
      transition(':enter', [
        style({ opacity: '0', width: '0' }),
        animate('.2s ease-out', style({ width: '*' })),
        animate('.2s .2s ease-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1', width: '*' }),
        animate('.2s ease-out', style({ opacity: '0' })),
        animate('.2s .2s ease-out', style({ width: '0' })),
      ])
    ]),
    trigger('dropzoneFade', [
      transition(':enter', [
        style({ width: '0' }),
        animate('.2s ease-out', style({ width: '*' })),
      ]),
      transition(':leave', [
        style({ width: '*' }),
        animate('.2s .2s ease-out', style({ width: '0' })),
      ])
    ]), // @formatter:on
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessTreeComponent implements OnInit, OnDestroy {
  @Input() domain: string;
  @Input() tree: Tree;
  @Input() nodeActivation: NodeActivation;
  @Input() openRows: RenderedProcess[];
  @Input() isAuthor: boolean;
  @ViewChildren(ProcessComponent) processes: QueryList<ProcessComponent>;

  treeRoot$ = this.store$.select(getTreeRoot);
  templatesShowing$ = this.store$.select(getTemplatesShowing);
  templates$ = this.store$.select(state => state.tree.templateNode);
  activeTemplateNode$ = new BehaviorSubject<string>(null);
  templatesWithActiveNode$ = combineLatest([this.templates$, this.activeTemplateNode$])
    .pipe(map(([templates, activeNode]) => {
      const updatedTemplates = JSON.parse(JSON.stringify(templates));
      const activeNodeUpdater = (node: RenderedElement) => {
        if (node.nodeType !== 'page' && node.id === activeNode) {
          node.activation = 'active'
        } else if (isRenderedProcess(node)) {
          [...node.children, ...node.bullets].forEach(child => activeNodeUpdater(child));
        }
      };
      if (!!activeNode) {
        activeNodeUpdater(updatedTemplates);
      }
      return updatedTemplates;
    }));
  activeTemplateNodeIsProcess$ = combineLatest([this.templates$, this.activeTemplateNode$])
    .pipe(map(([templates, activeNode]) => {
      return templates?.children.some(child => child.id === activeNode) || false;
    }));

  constructor(private interact: InteractService, private store$: Store<AppState>, private visitService: VisitService,
    private router: Router, private linkService: PromanLinkService, private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute) {
  }

  @HostBinding('class.pm-mainpage')
  public get isMainPage(): boolean {
    return this.nodeIsMainPage(this.tree);
  }

  private get anyActive(): boolean {
    return this.nodeActivation?.type && this.nodeActivation.type !== 'highlight';
  }

  nodeIsMainPage(tree: Tree): boolean {
    return tree.root && tree.nodes.get(tree.root)?.type === 'mainpage';
  }

  ngOnInit(): void {
    setTimeout(() => this.interact.register(), 250);
  }

  ngOnDestroy(): void {
    this.interact.unregister();
  }

  isActive(node: RenderedElement): boolean {
    return node?.activation !== null && node?.activation !== 'highlight';
  }

  public getActiveState(rowNode: RenderedProcess): string {
    if ((rowNode.nodeType === 'page' && this.isActive(rowNode)) ||
      (rowNode?.children || []).filter(childNode => this.isActive(childNode)).length > 0) {
      return this.isAuthor ? this.nodeActivation?.type : 'active';
    }
    return 'inactive';
  }

  getRowId(index: number, row: RenderedProcess): string {
    return row ? row.id : '';
  }

  getProcessWidth(root: Process): string {
    return convertToPixelSize(root.childSize).width + 'px';
  }

  activationChange($event: { element: RenderedElement; type: ActivationType }) {
    if (!!$event.type) {
      this.store$.dispatch(new ActivateElement({
        id: $event.element.id,
        activationType: $event.type
      }));
    } else {
      this.store$.dispatch(new DeactivateElement());
    }
  }

  elementClick(element: RenderedElement) {
    this.store$.select(getFormattingSourceNode).pipe(take(1)).subscribe((formattingSource) => {
      if (formattingSource.active) {
        if (!formattingSource.node) {
          this.store$.dispatch(new SetFormatSource({id: element.id}));
        } else {
          this.store$.dispatch(new ApplyFormat({
            targetId: element.id,
            sourceId: formattingSource.node.id,
            restyledLabel: extractStyle(element.label, formattingSource.node.label)
          }))
        }
      } else if (isRenderedProcess(element)) {
        if ((!element.isOpen || element.children?.length > 0 || element.nodeType !== 'process') && !this.anyActive) {
          let targetNode = element.id;
          if (element.isOpen && element.nodeType !== 'page' && !!element.parentId) {
            targetNode = element.parentId;
          }

          this.navigationService.navigateToNodeRelative(targetNode, this.activatedRoute)
            .catch(() => console.log('Could not navigate to node'));
        } else if (!this.isActive(element)) {
          this.store$.dispatch(new DeactivateElement());
        }
      }
    });
  }

  linkClick($event: { element: RenderedElement; link: Link | string }) {
    this.store$.select(getTreeNode($event.element.id)).pipe(take(1)).subscribe(process => {
      if (!!process.serverId) {
        this.visitService.registerVisit(process.serverId);
      }

      const link = $event.link;
      if (isLink(link) && link.linkType === 'info') {
        if (!!process.extendedInfo) {
          this.linkService.showEmbedDialog({
            title: process.info,
            html: process.extendedInfo,
            type: link.linkType
          });
        }
      } else if (!isLink(link) && new URL(link).protocol === 'pm-template-version:') {
        this.elementClick($event.element);
      } else {
        this.linkService.openLink(link);
      }
    });
  }

  resized($event: { element: RenderedElement; size: Size }) {
    this.store$.dispatch(new UpdateTreeElementSize({
      parentId: $event.element.parentId,
      size: $event.size
    }));
  }

  updated($event: { element: RenderedElement; content: ActivationType }) {
    this.store$.dispatch(new UpdateTreeElementText({
      id: $event.element.id,
      label: ensureWorkingFontFamily($event.content)
    }));
  }

  templateActivationChange($event: { element: RenderedElement, type: ActivationType }) {
    if ($event.type === 'active') {
      this.activeTemplateNode$.next($event.element.id);
    } else {
      this.activeTemplateNode$.next(null);
    }
  }

  templateElementClick($event: RenderedElement & Partial<TemplateId>) {
    if (isTemplateId($event)) {
      this.navigationService.navigateToTemplate({
        templateId: $event.templateId,
        templateVersion: $event.templateVersion
      });
    }
  }

  // noinspection JSUnusedLocalSymbols
  templateLinkClick($event: { element: RenderedElement; link: Link|string }) {
  }
}
